/*
* @Author: WYY
* @Description: 模拟练习
* @ModuleName: 模拟练习
* @Date: 2018-09-25 11:44:18
* @Last Modified by: WYY
* @Last Modified time: 2020-05-25 17:14:38
*/
<style lang="less" scoped>
    .question_des{
        background: #fff;
        .content_t{
            position: relative;
            .Img_show{
                position: relative;
                overflow: hidden;
                height: 100%;
                .deep{
                    max-width: 100%;
                    display: block;
                    margin: 0 auto;
                    max-height: 100%;
                }
                .out{
                    position: absolute;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .btnshow{
                display: block;
                width: 60px;
                height: 30px;
                background-image: linear-gradient(0deg,
                #d7d7d7 0%,
                #ffffff 100%),
                linear-gradient(
                        #e5e5e5,
                        #e5e5e5);
                background-blend-mode: normal,
                normal;
                border: solid 1px #b5b5b5;
                border-radius: 30px !important;
                position:absolute !important;
                left:0;
                top:.4rem;
                z-index: 10;
                opacity: 0.3;
                transition: .5s;
                font-size: 12px;
                line-height: 30px;
                &:hover{
                    opacity: 1;
                }
            }
        }

    }
    .Simu{
        transition: .5s;
        .t_content{
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>
<template>
        <div class="T_box">
            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <!-- <video :id="`myElement`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                        <!-- <div class="ShuiYinRight"> 中国民用航空西北地区空中交通管理局 </div> -->
                        <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                        <!-- 播放 -->
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;"> No.{{questionIndex+1}}/{{questionAll}} </span>
                                <div></div>
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>
                            <div class="Second_title_describe">
                                <div class="Question_list" v-show="Pattern!='测试模式'">
                                    <span class="t1">Question. {{childQuestionListIndex + 1}}/{{childQuestionListAll}}</span>
                                    <AudioPlayer @pauseall="pauseAll" ref="QuestionAudioPlayer" :theUrl="QuestionAudioPlayerUrl"  @audioEndedOver="DataChange"  :config="QuestionAudioPlayerConfig" />
                                    
                                </div>
                            </div>
                        
                        <div class="Question_box clearfix">
                            <div :class="['question_des',(threeActive=='')?'active':'']">
                                <div class="content_t">
                                    <div class="Img_show">
                                        <img :src="ImgControl.img1" alt="" class="deep" @load='Load()'>
                                        <img id="floatImg" v-if="ImgControl.show&&!!ImgControl.img2&&ImgControl.img2!=null&&ImgControl.img2!='null'" @load="twoLoad()" :src="ImgControl.img2" alt="" class="out">
                                    </div>
                                    <button v-if="ImgControl.show" class="FangdaTwo" @click="showImageUrl=ImgControl.img2;aShow=true;"> <i class='el-icon-search'/> </button>
                                    <button class="Fangda" @click="showImageUrl=ImgControl.img1;aShow=true;"> <i class='el-icon-search'/> </button>
                                    <button class="btnshow"  v-show="!!ImgControl.img2&&ImgControl.img2!=null&&ImgControl.img2!='null'" @click="ImgShow()"> Prebal </button>
                                </div>
                            </div>
                            <div class="Text Simu" v-show="threeActive=='TEXT'">
                                <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div style="height:100%;">
                                    <div class="t_content" style="border:none">
                                        <div class="T_con" id="Simu">
                                            <div class="S_box">
                                                <div v-for="(item,index) in childQuestionData.title" :key="index">
                                                    <div class="t_describe" v-if="item.playType == 3">
                                                        <img src="../../assets/t2.png" alt="">
                                                        <div v-html="item.content">
                                                        </div>
                                                    </div>
                                                    <div v-else :class="item.playType=='1'?'L_dialogue':'R_dialogue'">
                                                        <div class="Word_">
                                                            <p v-html="item.content"></p>
                                                            <span class="jiao"></span>
                                                        </div>
                                                        <div class="badge">
                                                            <img src="../../assets/t3.png" v-if="item.playType==1" alt="">
                                                            <img src="../../assets/t1.png" v-if="item.playType==2" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                 </div>

                            </div>
                            <div class="Notes" v-show="threeActive=='NOTES'">
                                <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                            </div>
                            <!-- key -->
                            <div class="Text" v-show="threeActive=='KEY'">
                                <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div class="t_content" v-html="AnswerKey"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="T_option">
                    <div class="Unit_option">
                        <div class="btn_list">
                            <!-- back -->
                            <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                            <!-- next -->
                            <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>

                            <div class="Record_btn" v-if="RecordeItem.show">
                                <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a('1')">
                                <img src="../../assets/play_bai.png"  v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn" class="T_2" alt="" @click='MediaPlay()'>
                                <img src="../../assets/pause.png"  v-if="Pattern!='测试模式' && RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn" class="T_2" alt="" @click='MediaPlay()'>
                                <div class="Re_conm"  v-if='RecordBtn'>
                                    <span class="horn"></span>
                                    <div class="Re_box">
                                        <div id="Wave">
                                            <div v-for="i in 10" class="WaveItem" :key="i" :style="'height:'+ Wheight(i) +'px'">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="countdown"> {{RecordeItem.timeshow}} </div>
                                </div>
                            </div>
                            <Recorder ref="Recorder" @upRcData="AddAudio"></Recorder>
                            <AudioPlayer ref="recorderPlayer" @audioEndedOver="recorderPlayerAudioEndedOver" :theUrl="recorderPlayerUrl"  :config="recorderPlayerConfig" />
                            <!-- <span v-if="Pattern!='测试模式'&&ScoreShow&&ComplateTest=='1'&&isJudgment=='1'" style="font-size:18px;display:inline-block;vertical-align:middle;"> 等级：{{this.TiScore}} </span>
                            <span v-if="!ScoreShow&&Pattern!='测试模式'&&ComplateTest=='1'&&isJudgment=='1' " style="font-size:18px;display:inline-block;vertical-align:middle;">打分中</span> -->

                            <div class="btn_cover"  v-if='RecordBtn'></div>
                        </div>
                        <Time :Pattern='Pattern'/>
                    </div>
                </div>
            </div>


            <!-- <div class="Machine"  v-if="Pattern!='测试模式'">
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="CheckKey()">
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
            </div> -->

            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>
            <amplification :show.sync='aShow' :url='showImageUrl'/>
        </div>
</template>

<script>
    import popup from '../describe/Popup.vue'
    import amplification from "../../components/amplification.vue";
    import Head from '../../components/head.vue'
    import videoJs from 'video.js'
    import "video.js/dist/video-js.min.css"
    export default {
        data(){
            return {
                mshow:{
                    text:2,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                Panduan:true,
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                RightLeft:false,
                pshow:false,
                questionData:{},
                childQuestionData:{},
                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0,
                childQuestionListAll:0,

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 对话 音频 播放器
                PlayerParameter:{
                    width:'500',
                    height:'500',
                    controls:true,
                    loop:false,
                    preload:'auto',
                    autoplay:true,
                },
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                check:'',
                complate:false, //是否提交答案
                answer:'',

                NoteItem:{
                    id:'',
                    content:'',
                },
                // 绑题 规则
                BindRole:{},

                RecordeItem:{
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    getAudio:null,
                    RecorderAudio: 0,  //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                    allow:false,
                },
                // 录音动态效果
                wave:null,
                waveIndex:0,
                AnswerKey:'',
                UpLayerData:null,

                InfoComplate:false, // 首次加载数据判断

                ImgControl:{
                    img1:'',
                    img2:'',
                    show:false,
                },

                ScrollControl:{
                    LastData:0,
                    Interval:null,
                },
                aShow:false,
                showImageUrl:'',
                QuestionAudioPlayerUrl:"",//问题音频地址
                QuestionAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[4],
                },
                recorderPlayerUrl:"",//录音播放地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
            }
        },
        components:{popup,amplification,Head},
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }
        },
        mounted(){
            // this.resizeQuestion_des()
            // window.onresize = ()=>{
            //     this.resizeQuestion_des()
            // }
        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            checkid:function(){
                return this.$store.state.common.checkid;
            },
            RecordBtn:function(){
                return this.$store.state.common.RecordBtn;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            checkid:function(){
                if(this.InfoComplate){
                    this.getinfo();
                }
            },
            mshow:{
                handler(v,oldv){
                    setTimeout(this.setLeft,501);
                },
                deep:true,
            },
            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv&&!this.complate){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
            threeActive:function(v,ov){
                setTimeout(()=>{
                    if(this.ImgControl.show){
                        let img = document.querySelector('.out');
                        let deep = document.querySelector('.deep');
                        let Img_show = document.querySelector('.Img_show');
                        let c = (Img_show.offsetWidth - deep.offsetWidth) /2;
                        img.style.top = "0";
                        img.style.left = c +"px";
                    }
                },100)
                if(!!this.moveTime){
                    clearTimeout(this.moveTime);
                    this.moveTime = null;
                }
                this.moveTime = setTimeout(this.reLoad,500);
                if(v=='KEY'&&!!this.CheckKey){
                    this.CheckKey();
                }
            },

        },
        methods:{
            pauseAll(){
                this.RecordeItem.MediaInPlay=false;
            },
            reLoad(){
                let deep = document.querySelector('.deep');
                let btn = document.querySelector('.btnshow');
                let fangda = document.querySelector('.Fangda');
                btn.style.display = 'none';
                let Img_show = document.querySelector('.Img_show');
                let c = (Img_show.offsetWidth - deep.offsetWidth) /2 ;
                c += 30;
                btn.style.left = c + 'px';
                fangda.style.right = (c+5) + 'px';
                fangda.style.bottom = '0.4rem';
                // fangda.style.top = (deep.offsetHeight) + 'px' ;
                let ImgControl = this.ImgControl;
                if(!!ImgControl.img2&&ImgControl.img2!=null&&ImgControl.img2!='null'){
                    btn.style.display = 'block';
                }
            },
            twoLoad(){ // 浮层图
                let Img_show = document.querySelector('.Img_show');
                let float = document.querySelector('#floatImg');
                let button = document.querySelector('.FangdaTwo');
                let basImg = document.querySelector('.deep');
                let d = (Img_show.offsetWidth - basImg.offsetWidth) /2  + float.offsetWidth + 5;
                let c = (Img_show.offsetWidth - float.offsetWidth) /2;
                c = c + float.offsetWidth;
                button.style.left = d + 'px';
                button.style.bottom = '0.4rem';
                // button.style.top = (float.offsetHeight+10) + 'px';
            },
            stayPause(){ // 测试模式暂停
                if(this.complate){
                    this.$message.warning('您本题已提交答案,无法暂停!');
                    this.$store.commit('setitem',{name:'testPause',item:true});
                    return false;
                }
                this.InPlay = false;
                if(!!this.player) this.player.paused();
                // 录音播放停止
                if(this.RecordeItem.MediaInPlay){
                    if(this.RecordeItem.RecorderAudio == 1){
                        this.RecordeItem.Media.pause();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }
                if(this.RecordBtn){
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    Recorder.stop();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }
            },
            async CheckKey(){
                let url_ = await this.AjaxApi.getAnswerById(this.childQuestionData.id);
                console.log(url_);

                let answerKey = '';
                if(!!url_.data.answers&&url_.data.answers!=null) answerKey = `<span>Answer</span><div>${url_.data.answers}</div> `;
                if(!!url_.data.answerKey&&url_.data.answerKey!=null) answerKey += `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;

                this.mshow.key=2;
                if(this.mshow.notes!=0) this.mshow.notes=1;
                if(this.mshow.text!=0) this.mshow.text=1;
            },
            setLeft(){
                let deep = document.querySelector('.deep');
                let btn = document.querySelector('.btnshow');
                let fangda = document.querySelector('.Fangda');
                btn.style.display = 'none';
                let Img_show = document.querySelector('.Img_show');
                let c = (Img_show.offsetWidth - deep.offsetWidth) /2 ;
                c += 30;
                btn.style.left = c + 'px';
                fangda.style.right = (c+5) + 'px';
                let ImgControl = this.ImgControl;
                if(!!ImgControl.img2&&ImgControl.img2!=null&&ImgControl.img2!='null'){
                    btn.style.display = 'block';
                }
            },
            Load(){
                this.setLeft();
            },
            ImgShow(){
                this.ImgControl.show = !this.ImgControl.show;
                setTimeout(()=>{
                    if(this.ImgControl.show){
                        let img = document.querySelector('.out');
                        let deep = document.querySelector('.deep');
                        let Img_show = document.querySelector('.Img_show');
                        let c = (Img_show.offsetWidth - deep.offsetWidth) /2;
                        img.style.top = "0";
                        img.style.left = c +"px";
                    }
                },100)

            },
            Wheight(i){
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t/5)*5 - t%5)) * 40 - 300) - 30;
                let d = 300*300 - c*c;
                d = Math.sqrt(d)*2;
                return d;
            },

            async getinfo(){
                let data = this.GetTargetItem();
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                this.BindRole = this.Target.bindRole;
                this.UpLayerData = data.updata; // 上 层数据
                console.log(data,"data");
                // 判断 学习模式
                this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName});
                this.$store.commit('setitem',{'name':'RoleIsJudgment','item': this.Target.bindRole.isJudgment});
                // this.$store.commit('setitem',{'name':'Pattern','item':'测试模式'});
                this.threeShow(this.BindRole);
                if(this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if(this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if(this.BindRole.isReadOriginal  == 1) this.mshow.text = 1;
                else this.mshow.text = 0;

                if(this.Target.isEnd == 1 && data.data.length>1){
                    this.RightLeft = true;
                }else{
                    this.RightLeft = false;
                }
                this.questionIndex = 0;
                this.questionAll = this.Target.questionList.length; // 问题 总数


                this.SetAnswer();
            },
            ReStart(){
                if(this.RecordBtn){
                    Recorder.stop();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }
                if(!!this.player) this.player.remove();
                if(this.RecordeItem.MediaInPlay) this.RecordeItem.Media.pause();
                if(!!this.wave) clearInterval(this.wave);
                if(!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
                this.RecordeItem.MediaInPlay = false;
                this.DataChange(0);
                // this.$message.info('重播!');
            },
            async SetAnswer(){
                let itemM = this.Target.questionList[this.questionIndex];
                let mainId = '';
                if(this.Pattern == '测试模式' && !!this.Target.childList && this.Target.childList.length>0){
                    mainId = itemM.mainId;
                }else{
                    mainId = this.Target.sectionMain.id;
                }
                let questiondata = await this.GetQuestion(itemM.oldId,mainId);
                this.questionData = questiondata.data;
                if(this.Pattern == '测试模式'){
                    let dan = this.questionData.childQuestionList[itemM.num];
                    this.questionData.childQuestionList = [dan];
                }
                this.childQuestionListIndex = 0;
                this.childQuestionListAll = this.questionData.childQuestionList.length;
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];

                let titleData = JSON.parse(this.childQuestionData.title);
                console.log(titleData,'titleData');
                if(Array.isArray(titleData)){
                    titleData.sort((a,b)=>{
                        return parseInt(a.playOrder) - parseInt(b.playOrder);
                    })
                }
                this.childQuestionData.title = titleData;
                this.childQuestionData.options = JSON.parse(this.childQuestionData.options);
                this.childQuestionData.progress = 0; // 播放进度 设置
                let item = this.childQuestionData.title[this.childQuestionData.progress];

                // playOrder 正序
                // playType 1 播放 2 录音  1 左 2 右  3 提示音
                // 图片
                if(this.childQuestionData.options && this.childQuestionData.options.mainImage){
                    this.childQuestionData.options.mainImage = this.childQuestionData.options.mainImage.replace('/Images/','');
                }
                if(this.childQuestionData.options && this.childQuestionData.options.floatImage){
                    this.childQuestionData.options.floatImage = this.childQuestionData.options.floatImage.replace('/Images/','');
                }
                this.ImgControl.img1 = this.$store.state.common.ImgUrl +  this.childQuestionData.options.mainImage;
                if(this.childQuestionData.options.floatImage==''||!!!this.childQuestionData.options.floatImage){
                    this.ImgControl.img2 = '';
                }else{
                    this.ImgControl.img2 = this.$store.state.common.ImgUrl +  this.childQuestionData.options.floatImage;
                }

                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false; 
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }

                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);


                if(!!notes_&&!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                this.InfoComplate = true;

                if(item.playType == 1 || item.playType == 3){
                    // 赋值音频路径
                    this.QuestionAudioPlayerUrl = item.audioUrl
                    if(this.BindRole.isAutoPaly == 1){
                        this.QuestionAudioPlayerConfig.autoplay = true
                    }else{
                        this.QuestionAudioPlayerConfig.autoplay = false
                    }
                    console.log(item.playType,"");
                    // this.PlayerParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(item.audioUrl) }];
                    // this.PlayReady();
                    // this.RecordeItem.allow = false;
                }
                if(item.playType == 2){
                    if(this.BindRole.haveRecordDown == 1){
                        this.RecordeItem.allow = true;
                        this.Record_a();
                    }
                }
            },
            // 播放器准备
            PlayReady(){
                if(!!this.player){
                    this.player.reset(); //重置 video
                    this.player.src(this.PlayerParameter.sources);
                    this.player.load();
                    this.player.currentTime(0.5);
                    this.player.play();
                    return false;
                }
                this.player = videoJs('myElement',this.PlayerParameter);
                this.player.on('ready',()=>{
                    if(this.PlayerParameter.autoplay){
                        this.player.play();
                        this.InPlay = true;
                    }
                })
                this.player.on('canplaythrough',()=>{
                    console.log('canplaythrough');
                    if(this.PlayerParameter.autoplay){
                        this.player.play();
                        this.InPlay = true;
                    }
                })
                this.player.on('ended',()=>{
                    console.log('播放完成!');
                    this.DataChange();
                })
            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                if(item.content){
                    item.content = item.content.replace(/(^\s*)|(\s*$)/g,'');
                }
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }

                if(!!item.id){
                    let edit_ = await this.AjaxApi.updateCourseNote(item);

                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add_ = await this.AjaxApi.addCourseAdvice(data);

                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                    this.Record_a();
                }
            },

            // 录音  控制
            Record_a(it){
                console.log(it,this.RecordeItem);
                if(it=='1'){
                    if(this.Pattern == '测试模式'){
                        return;
                    }
                }

                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }
                let QuestionAudioPlayerState = this.$refs.QuestionAudioPlayer.getPlaystate()
                if(QuestionAudioPlayerState){
                    this.$message.warning('尚未到达录音步骤!');
                    return;
                }
                // if(!this.RecordeItem.allow){
                //     this.$message.warning('尚未到达录音步骤!');
                //     return;
                // }
                this.$store.commit('setAdd','MenuSwitch');
                if(this.RecordeItem.MediaInPlay){
                    if(this.RecordeItem.RecorderAudio == 1){
                        this.RecordeItem.Media.pause();
                    }
                    if(this.RecordeItem.RecorderAudio == 2){
                        Recorder.stop();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }

                this.waveIndex = 0;
                this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;
                console.log(this.recordTime);
                if(!!this.BindRole.recordDownTime&&this.BindRole.recordDownTime>0){
                    this.RecordeItem.time = parseInt(this.BindRole.recordDownTime);
                }else{
                    this.RecordeItem.time = 35;
                }
                this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);

                if(this.RecordBtn){
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll('#Wave div');
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = '';
                    }
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }else{
                    this.$store.commit('setitem',{'name':'RecordBtn','item':true})
                    this.wave = setInterval(()=>{
                        let list = document.querySelectorAll('#Wave div');
                        if(list[this.waveIndex ].className.indexOf('active')>-1){
                            list[this.waveIndex ].className = '';
                        }else{
                            list[this.waveIndex ].className = 'active';
                        }
                        if(this.waveIndex<9){
                            this.waveIndex++;
                        }else{
                            this.waveIndex = 0;
                        }
                    },35)
                    this.RecordeItem.Count = setInterval(()=>{
                        if(this.RecordeItem.time>0){
                            this.RecordeItem.time --;
                            this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                        }else{
                            this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;
                            console.log(this.recordTime);
                            this.RecordeItem.RecorderAudio = 2;
                            Recorder.stop();
                            this.UpdateAudio();
                            this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }
                    },1000)
                    this.$refs.Recorder.handleBtnClick()
                    // Recorder.record({
                    //     start: function () {
                    //         console.log('开始录音!');
                    //     },
                    //     progress:(m,z)=>{ // 录音 过程

                    //     },
                    // });

                }

            },
            async UpdateAudio(){
                this.ComplateTest = '1';
                await this.$refs.Recorder.stopVoice()
                // Recorder.upload({
                //     url: this.$store.state.common.UploadAudio,
                //     audioParam: "file",
                //     success: (msg)=>{
                //         this.complate = true;
                //         this.AddAudio(msg);
                //     }
                // });
                console.log('上传完成');
            },
            async AddAudio(url_){
                console.log('新增音频记录',url_);
                this.recorderPlayerUrl = url_.data
                let path = url_.data;
                // 新增录音记录
                let data = {
                    courseId:this.$store.state.common.curriculum,
                    filePath:path,
                    questionId:this.childQuestionData.id,
                    sectionId:this.Target.id,
                    recordTime:this.recordTime,
                    seatMealCourseId:sessionStorage.getItem('seatMealCourseId')||'abc',
                    uuid:sessionStorage.getItem('uuid'),
                }
                let add_ = await this.AjaxApi.addVideo(data);
                let item = this.childQuestionData.title[this.childQuestionData.progress];
                let fo = {
                    url:path,
                    type:'4',
                    bigTitleId:this.questionData.id,
                    smallTitleId:this.childQuestionData.id,
                    standardAnswer:item.content,
                }
                await this.GetScore(fo);
                this.DataChange();
            },
            // 录音播放完成
            recorderPlayerAudioEndedOver(){
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
            },
            // 已录制 音频 播放
            MediaPlay(){
                let QuestionAudioPlayerState = this.$refs.QuestionAudioPlayer.getPlaystate()
                if(QuestionAudioPlayerState ){
                    this.$message.warning('请听完音频!');
                    return false;
                }
                this.$refs.recorderPlayer.startPlayOrPause()
                // if(this.RecordeItem.RecorderAudio == 0){ // 未录音
                //     this.$message.warning('请先录制音频!');
                //     return false;
                // }
                // if(this.InPlay){
                //     this.InPlay = false;
                //     this.player.pause();
                // }
                // if(this.RecordeItem.MediaInPlay){
                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.pause();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.stop();
                //     }
                // }else{
                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.play();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.play({  // 播放 监听
                //             progress: function (milliseconds) {},
                //             finished:this.PlayChange,
                //         });
                //     }
                // }
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
            },
            // 对话 列表 数据改变
            DataChange(index){
                if(index===0) this.childQuestionData.progress = 0;
                if(this.childQuestionData.progress < (this.childQuestionData.title.length-1)){
                    if(index===0) this.childQuestionData.progress = 0;
                    else this.childQuestionData.progress++;
                    // 播放 滚动条
                    if(index===0){
                        let target = document.querySelector('#Simu');
                        if(!!target){
                            target.scrollTop = 0;
                            this.ScrollControl.LastData = 0;
                        }
                    }else{
                        this.ScrollControl.Interval = setInterval(()=>{
                            if(this.mshow.text==2){
                                let list = document.querySelectorAll('.S_box>div');
                                let target = document.querySelector('#Simu');
                                let hei = list[this.childQuestionData.progress].offsetTop || 0;
                                if(!!hei){
                                    if(hei>target.scrollTop){
                                        target.scrollTop+=1;
                                    }
                                    if(hei<target.scrollTop){
                                        target.scrollTop-=1;
                                    }
                                    if(this.ScrollControl.LastData == target.scrollTop){
                                        clearInterval(this.ScrollControl.Interval);
                                    }
                                    if(hei>(target.scrollTop-5)&&hei<(target.scrollTop+5)){
                                        clearInterval(this.ScrollControl.Interval);
                                    }
                                    this.ScrollControl.LastData = target.scrollTop;
                                }

                            }

                        },10)
                    }
                    let item = this.childQuestionData.title[this.childQuestionData.progress];
                    if(!!this.player) this.player.paused();

                    if(item.playType == 1 || item.playType == 3){ //音频播放
                        // 赋值音频路径
                        this.QuestionAudioPlayerUrl = item.audioUrl
                        if(this.BindRole.isAutoPaly == 1){
                            this.$refs.QuestionAudioPlayer && this.$refs.QuestionAudioPlayer.startPlayOrPause()
                        }else{

                        }
                        // // 音频源替换
                        // this.PlayerParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(item.audioUrl) }];
                        // this.PlayReady();
                        this.RecordeItem.allow  = false;
                    }
                    if(item.playType == 2){
                        if(this.BindRole.haveRecordDown == 1){
                            this.RecordeItem.allow  = true;
                            this.Record_a();
                        }
                    }
                }else if(this.childQuestionData.progress == (this.childQuestionData.title.length-1)){
                    this.RecordeItem.allow  = true;
                    if(this.Pattern == '测试模式'){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }
                }
            },

            // 上一题
            prevQuestion(){
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            nextQuestion(){
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange(){
                if(!!this.player) this.player.pause();
                if(this.RecordeItem.MediaInPlay) this.RecordeItem.Media.pause();
                if(!!this.wave) clearInterval(this.wave);
                if(!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
                this.waveIndex = 0;
                this.wave = null;
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.RecordeItem = {
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    getAudio:null,
                    RecorderAudio: 0,  //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                    allow:false,
                }
                this.ImgControl = {
                    img1:'',
                    img2:'',
                    show:false,
                };
                this.NoteItem = {
                    id:'',
                    content:'',
                };
                this.RecordeItem.Media = document.createElement('audio');
                this.SetAnswer();
            },

            // 上一子题
            prevChildQuestion(){
                this.childQuestionListIndex--;
                this.SetChildQuestion();
            },
            // 下一子题
            nextChildQuestion(){
                this.childQuestionListIndex++;
                this.SetChildQuestion();
            },
            // 子题信息设置
            SetChildQuestion(){
                if(!!this.player) this.player.pause();
                if(this.RecordeItem.MediaInPlay) this.RecordeItem.Media.pause();
                if(!!this.wave) clearInterval(this.wave);
                if(!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
                this.waveIndex = 0;
                this.wave = null;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.RecordeItem = {
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    getAudio:null,
                    RecorderAudio: 0,  //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                    allow:false,
                }
                this.ImgControl = {
                    img1:'',
                    img2:'',
                    show:false,
                };
                this.NoteItem = {
                    id:'',
                    content:'',
                }
                this.RecordeItem.Media = document.createElement('audio');

                this.setChildinfo();
            },
            async setChildinfo(){
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.childQuestionData.title = (this.childQuestionData.title instanceof Array)?this.childQuestionData.title:JSON.parse(this.childQuestionData.title);
                this.childQuestionData.options = (typeof(this.childQuestionData.options)=='string')?JSON.parse(this.childQuestionData.options):this.childQuestionData.options;

                this.childQuestionData.progress = 0; // 播放进度 设置
                let item = this.childQuestionData.title[this.childQuestionData.progress];
                if(item.playType == 1 || item.playType == 3){
                    // 赋值音频路径
                    this.QuestionAudioPlayerUrl = item.audioUrl
                    // this.PlayerParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(item.audioUrl) }];
                    // this.PlayReady();
                    // this.RecordeItem.allow = false;
                }
                if(item.playType == 2){
                    // this.$message.info('请录制音频!');
                    this.RecordeItem.allow = true;
                }
                console.log(this.childQuestionData.options.floatImage);

                // 图片
                if(this.childQuestionData.options && this.childQuestionData.options.mainImage){
                    this.childQuestionData.options.mainImage = this.childQuestionData.options.mainImage.replace('/Images/','');
                }
                if(this.childQuestionData.options && this.childQuestionData.options.floatImage){
                    this.childQuestionData.options.floatImage = this.childQuestionData.options.floatImage.replace('/Images/','');
                }
                this.ImgControl.img1 = this.$store.state.common.ImgUrl + this.childQuestionData.options.mainImage;
                if(!!this.childQuestionData.options.floatImage&&this.childQuestionData.options.floatImage!=''){
                    this.ImgControl.img2 = this.$store.state.common.ImgUrl + this.childQuestionData.options.floatImage;
                }else{
                    this.ImgControl.img2 = '';
                }

                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false; 
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = 1;
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);

                if(notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                this.$store.commit('setitem',{'name':'RecordBtn','item':false});
                await this.CheckKey();
            },
            On_back(){
                this.ComplateTest = 0;
                this.T_back(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            On_next(){
                this.ComplateTest = 0;
                if(this.$store.state.common.MaxPattern=='闯关模式'){
                    if(this.childQuestionData.progress==(this.childQuestionData.title.length-1)){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }else{
                        this.$message.warning('请先完成本题!');
                    }
                }else{
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },
        },
        destroyed() {
            Recorder.stop();
            if(!!this.player) this.player.dispose();
            if(this.RecordeItem.MediaInPlay) this.RecordeItem.Media.pause();
            if(!!this.wave) clearInterval(this.wave);
            if(!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
        },
    }
</script>

<style lang="less" scoped>
</style>



